import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import Footer from 'app/components/footer/footer'
import NavigationBar from 'app/components/navigation/navigationBar'
import Images from 'app/assets/images'

function About() {
  return (
    <>
    {/* About Section */}
      
    <Grid container spacing={2} sx={{ padding: '5%', bgcolor: '#feeeec' }} >
          <Grid item xs={12} md={6} justifyContent="center" >
            <Box  sx={{ textAlign: 'center', padding: { xs: '10px', md: '40px' }, marginLeft:{ xs:'1px', md: '5%'} , Width: {xs: 'auto', md:'80%'} }} style={{ maxWidth: '80%', height: 'auto',}} >
              <Typography variant="h5" align="left" sx={{ fontFamily: 'Indie Flower', marginBottom: '10px', fontSize: { xs: '18px', md: '35px' },  marginLeft: '20px'  }}>
                Revel in your style
              </Typography>
              <Typography variant="h6" align="left" sx={{ fontFamily: 'PT Sans', marginBottom: '20px', marginLeft: '20px', fontSize: { xs: '15px', md: '18px' }, Width: {xs: '1px', md:'80%'} }}>
                Everyone is different and we have different styles. Don't conform to what people want you to be. Express yourself through personalized embroidered fashion.
              </Typography>
              <Typography variant="h6" align="left" sx={{ fontFamily: 'PT Sans', marginBottom: '25px', marginLeft: '20px', fontSize: { xs: '15px', md: '18px' },  Width: {xs: '1px', md:'80%'} }}>
                Shop our exclusive blank products, then customize them with our original designs. You can also upload your designs and tell us how you want them customized.
              </Typography>

              <Typography variant="h5" align="left" sx={{ fontFamily: 'Indie Flower', marginBottom: '10px', fontSize: { xs: '18px', md: '35px' }, marginTop: '30px', marginLeft: '20px' }}>
                Shop like the gods
              </Typography>
              <Typography variant="h6" align="left" sx={{ fontFamily: 'PT Sans', marginBottom: '20px', marginLeft: '20px', fontSize: { xs: '15px', md: '18px' }, Width: {xs: '1px', md:'80%'} }}>
                Brooklyn Made Kings is located in Brooklyn, New York. Our collections are a reflection of the rich, eclectic, and soulful culture of our community. We would like to share this culture with you through art and fashion.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ padding: {xs: '1px',md : '25px'} }}>
                {/* <Typography variant="h4" sx={{fontFamily: 'PT Sans', fontSize: { xs: '15px', md: '35px', marginBottom: '10px' } }}>Embroidered Fashion Fit for the Gods</Typography> */}
                <img src={Images.aboutimage} alt="About" style={{ maxWidth: '90%', height: 'auto',}} />
            </Box>
          </Grid>
        </Grid>
      {/* <Box sx={{ backgroundImage: `url(${Images.banner})`, width: 1, height: '500px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }} />
      <Box sx={{ mt: 4, mb: 6 }}>
        <Grid container spacing={1} alignItems="center" justifyContent={"center"}>
          <Grid item md={6}>
            <Typography variant="h2">
              Who we are
            </Typography>
            <Typography variant="h6" sx={{ my: 1, color: '#333' }}>
              We are an embroidery company that focuses on small orders. We specialize in hoodies, hats, jackets, and bags. Our mission is to help our customers express themselves through customizable embroider apparel. Our Design Library contains original artwork as well as curated stock images that can be place on high quality apparel. We want you to stay original and to revel in your style.
            </Typography>
          </Grid>
        </Grid>
      </Box> */}
    </>
  )
}

export default About